import React from 'react';

const HowToStart = () => {
    const steps = [
        {
            number: 1,
            title: 'Create an Account',
            description: 'Visit our website and click on the "Sign Up" button. Fill in the required information, including your email address, and create a secure password. Verify your email to complete the registration process. Welcome to Flobux!',
        },
        {
            number: 2,
            title: 'Secure Your Account',
            description: 'Enable two-factor authentication (2FA) to add an extra layer of security to your account. This ensures that only you can access your account, even if someone else knows your password. We prioritize your security, so take this step to protect your assets.',
        },
        {
            number: 3,
            title: 'Deposit Funds',
            description: 'Once your account is set up and secured, it’s time to deposit funds. Go to the "Deposit" section and choose the cryptocurrency you want to add to your Flobux wallet. Follow the instructions to transfer your assets from your external wallet to your Flobux account.',
        },
        {
            number: 4,
            title: 'Start Trading',
            description: 'With funds in your account, you can start trading. Navigate to the "Trade" section to explore available trading pairs. Select the tokens you want to swap, enter the amount, and confirm the transaction. Enjoy zero-fee trading on all spot currency swaps!',
        },
        {
            number: 5,
            title: 'Explore Staking and Liquidity Pools',
            description: 'Maximize your earnings by exploring our staking and liquidity pool features. Go to the "Stake" section to stake your tokens and earn rewards. Alternatively, visit the "Liquidity Pools" section to provide liquidity and earn a share of the trading fees. Put your assets to work and watch your earnings grow.',
        }
    ];

    return (
        <div className='how-to-start-section'>
            <div className='why-us-title mt-5'>
                <h1>How to Start Using <span className='highlight'>Flobux</span>?</h1>
                <p>Getting started with Flobux is easy. Follow these five simple steps to begin trading, staking, and earning with our platform.</p>
            </div>
            <button className='get-started-btn'>Get Started</button>
            {steps.map((step) => (
                <div key={step.number} className='step-card'>
                    <div className='step-number'>{step.number}</div>
                    <div className='step-info'>
                        <h3>{step.title}</h3>
                        <p className='m-0'>{step.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HowToStart;
