import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './Header'
import Home from './HomePage_Components/Home'
import Footer from './Footer'
import Swap from './SwapPage_Components/Swap'
import AddLiquidity from './LiquidityPage_Components/AddLiquidity'
import Liquidity_Empty from './LiquidityPage_Components/Liquidity_Empty'
import Liquidity2 from './LiquidityPage_Components/Liquidity2'
import Send from './SendAndReceive.js/Send'
import Receive from './SendAndReceive.js/Receive'
import Stake from './StakePage_Components/Stake'
import LiquidityPoolsTable from './LiquidityPage_Components/LiquidityPoolsTable'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/liquidity" element={<Liquidity_Empty />} />
          <Route path="/liquidity2" element={<Liquidity2 />} />
          <Route path="/add-liquidity" element={<AddLiquidity />} />
          <Route path="/send" element={<Send />} />
          <Route path="/receive" element={<Receive />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/liquidity-pools-table" element={<LiquidityPoolsTable />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  )
}

export default App
