import React from 'react';
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaTiktok, FaLinkedinIn, FaPinterestP, FaGlobe } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container container">
        <div className="footer-column col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="footer-logo" style={{ textAlign: 'center'}}>
            {/* <img src="/images/logo.svg" alt="Polydesk" /> */}
            <h1>Polydesk</h1>
          </div>
          <p>Discover the future of online transactions with Flobux, a blockchain-based payment solution that ensures your money is always safe, fast, and accessible. Join us in transforming how the world moves money.</p>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-6 col-12">
          <h4>Quick Link</h4>
          <div className='d-flex flex-column gap-1'>
            <a href='#'>Home</a>
            <a href='#why-choose-us'>About Us</a>
            <a href='#our-services'>Our Service</a>
            <a href='#'>Careers</a>
            <a href='#contact-us'>Contact Us</a>
          </div>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-6 col-12">
          <h4>Office Address</h4>
          <p>700 S Rosemary Ave, Ste 204 West Palm Beach, FL 33401</p>
          <h4>Office Phone</h4>
          <p>+19175280494</p>
        </div>
        <div className="footer-column col-lg-3 col-md-4 col-sm-12 col-12">
          <h4>Stay Tuned With Us</h4>
          <form className="subscribe-form">
            <input type="email" placeholder="Email Address" />
            <button className='primary-button' type="submit">Subscribe Now</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom container">
        <div className="footer-bottom-links">
          <div className="footer-bottom-left d-flex">
            <span className='d-flex align-items-center'><FaGlobe /> USA</span>
            <span>Terms of Service</span>
            <span>Privacy Policy</span>
            <span>Sitemap</span>
            <span>Privacy Choices</span>
          </div>
          <div className="footer-bottom-right d-flex">
            <FaFacebookF />
            <FaTwitter />
            <FaYoutube />
            <FaInstagram />
            <FaTiktok />
            <FaLinkedinIn />
            <FaPinterestP />
          </div>
        </div>
      </div>
      <p className="copyright-text text-center mt-5 mb-0">Copyright 2024 Designed & Developed By Dreamvessels Technology Powered by Polydesk</p>
    </footer>
  );
};

export default Footer;


      