import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const Liquidity2 = () => {
  const tokens = [
    { label: 'USTD / ETH', name: 'Tether / Ethereum', image: '/images/usdt.png' },
    { label: 'BNB / ETH', name: 'Binance Coin / Ethereum', image: '/images/bnb.png' },
    { label: 'BTC / ETH', name: 'Bitcoin / Ethereum', image: '/images/btc.png' },
    { label: 'ADA / ETH', name: 'Cardano / Ethereum', image: '/images/ada.png' },
  ];

  return (
    <div className="swap-container">
      <div className="modal-content">
        <div className="modal-header mb-5">
          <div className='text-start'>
            <h3>Your Liquidity</h3>
            <p>List of Your Liquidity Positions</p>
          </div>
        </div>
        <div className="modal-body mb-5">
          <div className="token-list">
            {tokens.map((token) => (
              <div
                key={token.label}
                className="token-item"
              >
                <div className='token-details'>
                  <img src={token.image} alt={token.label} />
                  <div className='token-name'>
                    <h6 className='m-0'>{token.label}</h6>
                    <p className='m-0'>{token.name}</p>
                  </div>
                </div>
                <span className='right-arrow'><FaArrowRight /></span>
              </div>
            ))}
          </div>
        </div>
        <button className="connect-wallet-btn">+ Add Liquidity</button>
      </div>
    </div>
  );
};

export default Liquidity2;
