import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const ChooseProviderModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;


    const tokens = [
        { label: 'Mercuryo', name: '0.255 BNB', image: '/images/Mercuryo.png' },
        { label: 'Transak', name: '0.255 BNB', image: '/images/Transak.png' },
        { label: 'Moonpay', name: '0.255 BNB', image: '/images/Moonpay.png' },
    ];

    return (
        <div className="modal-overlay">
            <div className="choose-provider-modal-content">
                <div className="modal-header">
                    <div className='text-start'>
                        <h3>Choose Provider</h3>
                        <p>Choose one of the providers below</p>
                    </div>
                    <button onClick={onClose}>X</button>
                </div>
                <div className="modal-body">
                    <div className="token-list">
                        {tokens.map((token) => (
                            <div
                                key={token.label}
                                className="token-item"
                            >
                                <div className='token-details'>
                                    <img src={token.image} alt={token.label} />
                                    <div className='token-name'>
                                        <h6 className='m-0'>{token.label}</h6>
                                        <p className='m-0'>{token.name}</p>
                                    </div>
                                </div>
                                <span className='right-arrow'><FaArrowRight /></span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseProviderModal;
