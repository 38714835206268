import React from 'react'

const OurMainFeatures = () => {
    return (
        <>
            <div className='container'>
                <div className='why-us my-5'>
                <div className='why-us-title mt-5'>
                    <h1>Our Main Features</h1>
                </div>
                    <section className="alignment-one">
                        <div className="alignment-one-text">
                            <h2>Zero-Fee Trading</h2>
                            <p>Flobux offers zero-fee trading on all spot currency swaps, making it the most cost-effective platform for cryptocurrency trading. Our users can trade without worrying about high transaction costs, keeping more of their hard-earned money.</p>
                        </div>
                        <div className="alignment-one-image" style={{width:'30%'}}>
                            <img src="/images/feat1.png" alt="Man holding a product" />
                        </div>
                    </section>
                    <section className="alignment-two">
                        <div className="alignment-one-text">
                            <h2>Staking and Earning</h2>
                            <p>Put your digital assets to work with Flobux's staking feature. Earn rewards by staking your tokens directly on our platform. The longer you stake, the more you earn, providing a simple and effective way to grow your wealth.</p>
                        </div>
                        <div className="alignment-one-image" style={{width:'30%'}}>
                            <img src="/images/feat2.png" alt="Man holding a product" />
                        </div>
                    </section>
                    <section className="alignment-one">
                        <div className="alignment-one-text">
                            <h2>Liquidity Pools</h2>
                            <p>Our liquidity pools offer users the opportunity to earn passive income by providing liquidity to our platform. By depositing tokens into our pools, users can earn a share of the trading fees, contributing to a robust and liquid trading environment.</p>
                        </div>
                        <div className="alignment-one-image" style={{width:'30%'}}>
                            <img src="/images/feat3.png" alt="Man holding a product" />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default OurMainFeatures
