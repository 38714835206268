import React from 'react';
import { FaUser, FaShoppingCart, FaSearch, FaUserAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar">
      <div className="container-fluid navbar-container">
        <NavLink className="navbar-brand d-flex align-items-center" to="/#">
          <h1 className='m-0 text-white'>Polydesk</h1>
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse navbar-items" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#about-us">About Us</a>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/swap">Swap</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/liquidity">Liquidity</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/stake">Stake</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/send">Send & Receive</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="#">
                <button className='primary-button'>
                  SIGN IN
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
