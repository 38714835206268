import React from 'react';

const PaymentProcessingModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="qr-modal-content">
                <div className="modal-header d-flex justify-content-end">
                    <button onClick={onClose}>X</button>
                </div>
                <div className="modal-body">
                    <img src="/images/payment_processing.png" alt="QR Code" />
                    <div className='qr-content text-center  '>
                        <h5 className='m-1 mt-3'>Payment Processing</h5>
                        <p className='m-1'>Please wait for a few seconds</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentProcessingModal;
