import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimonial = () => {
    return (
        <div className='testimonial-section'>
            <h1 className='mb-5'>What Our Clients Say</h1>
            <Swiper
                slidesPerView={1}
                centeredSlides={true}
                speed={1500}
                loop={true}
                navigation={true}
                modules={[Autoplay, Navigation]}
            >
                <SwiperSlide>
                    <div className='testimonial-card'>
                        <div className='testimonial-content'>
                            <div className='testimonial-user-info'>
                                <img src='/images/photo.png' alt='Megan Jackson' className='testimonial-image' />
                                <h4>Megan Jackson</h4>
                                <p className='testimonial-role'>Crypto-enthusiast</p>
                            </div>
                            <div className='testimonial-text'>
                                <p>“Amazing platform! I've been using Flobux for a few weeks now, and I'm impressed by how easy it is to use.<br/> The zero-fee trading is a game-changer, and the staking rewards are a nice bonus. Highly recommend!”</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='testimonial-card'>
                        <div className='testimonial-content'>
                            <img src='/images/photo.png' alt='Megan Jackson' className='testimonial-image' />
                            <h4>Megan Jackson</h4>
                            <p className='testimonial-role'>Crypto-enthusiast</p>
                            <div className='testimonial-text'>
                                <p>“Amazing platform! I've been using Flobux for a few weeks now, and I'm impressed by how easy it is to use. The zero-fee trading is a game-changer, and the staking rewards are a nice bonus. Highly recommend!”</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='testimonial-card'>
                        <div className='testimonial-content'>
                            <img src='/images/photo.png' alt='Megan Jackson' className='testimonial-image' />
                            <h4>Megan Jackson</h4>
                            <p className='testimonial-role'>Crypto-enthusiast</p>
                            <div className='testimonial-text'>
                                <p>“Amazing platform! I've been using Flobux for a few weeks now, and I'm impressed by how easy it is to use. The zero-fee trading is a game-changer, and the staking rewards are a nice bonus. Highly recommend!”</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Testimonial;
