import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const AddLiquidity = () => {
    const [selectedCurrency1, setSelectedCurrency1] = useState('ETH');
    const [selectedCurrency2, setSelectedCurrency2] = useState('DAI');

    const currencies = [
        { value: 'ADA', label: 'ADA', image: '/images/ada.png' },
        { value: 'BNB', label: 'BNB', image: '/images/bnb.png' },
        { value: 'ETH', label: 'ETH', image: '/images/eth.png' },
        { value: 'DASH', label: 'DASH', image: '/images/dash.png' },
        { value: 'DAI', label: 'DAI', image: '/images/dai.png' },
        { value: 'ETC', label: 'ETC', image: '/images/etc.png' },
        { value: 'NEXO', label: 'NEXO', image: '/images/nexo.png' },
        { value: 'UNI', label: 'UNI', image: '/images/uni.png' },
    ];
    return (
        <div className="swap-container">
            <div className="add-liquidity-content">
                <div className='liquidity-header'>
                    <h5 className='mb-2'>Your Liquidity</h5>
                    <p className='mb-4'>List of Your Liquidity Positions</p>
                </div>
                <img className='add-liquidity-image' src="/images/liquidity_page.png" alt="QR Code" />
                <div className='liquidity-bottom-content'>
                    <p className='mb-4'>Your active V3 liquidity positions will appear here</p>
                </div>
                <div className="swap-box">
                    <div className=' text-start'>
                        <h5 className='mb-4'>Add Liquidity</h5>
                    </div>
                    <div className="swap-input">
                        <div className="custom-select">
                            <div className="selected-option">
                                <img src={currencies.find(cur => cur.label === selectedCurrency1).image} alt={selectedCurrency1} />
                                <p className='m-0'>{selectedCurrency1}</p>
                                <FaChevronDown className="arrow" />
                            </div>
                        </div>
                        <input id="eth-input" type="text" placeholder="500" />
                        <span className="usd-value">~156271.40 USD</span>
                    </div>
                    <div className="swap-balance mb-4">
                        <p>Balance: <span>4242.04360 ETH</span></p>
                    </div>

                    <div className="swap-input">
                        <div className="custom-select" >
                            <div className="selected-option">
                                <img src={currencies.find(cur => cur.label === selectedCurrency2).image} alt={selectedCurrency2} />
                                <p className='m-0'>{selectedCurrency2}</p>
                                <FaChevronDown className="arrow" />
                            </div>
                        </div>
                        <input id="dai-input" type="text" placeholder="22619.5" />
                        <span className="usd-value">~22673.50 USD</span>
                    </div>
                    <div className="swap-balance mb-4">
                        <p>Balance: <span>129.000 DAI</span></p>
                    </div>

                    <div className="swap-details">
                        <div className='swap-details2'>
                            <p><span>ETH per DAI:</span><span> 23.5302502</span></p>
                            <p><span>DAI per ETH:</span><span> 0.0530240</span></p>
                            <p><span>Share of Pool:</span><span> 0.00%</span></p>
                        </div>
                    </div>
                    <button className="connect-wallet-btn">Enter an amount</button>
                </div>
            </div>
        </div>
    );
};

export default AddLiquidity;
