import React from 'react';

const TradeFree = () => {
    return (
        <section className="trade-free" id='trade-free-banner'>
            <div className='trade-free-inside container my-5'>
                <h2>TRADE FEE-FREE WITH</h2>
                <h1>FLOBUX</h1>
                <p className='weighted-text'>Join Flobux today and enjoy fee-less trading on our decentralized exchange. </p>
                <p>Swap, stake, and earn rewards without worrying about high fees. It's time to take control of your finances with Flobux!</p>
                <button className='primary-button'>Start Trading</button>
            </div>
        </section>
    );
};

export default TradeFree;
