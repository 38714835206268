import React from 'react';

const Liquidity_Empty = () => {
    return (
        <div className="swap-container">
            <div className="liquidity-content">
                <div className='liquidity-header'>
                    <h5 className='mb-2'>Your Liquidity</h5>
                    <p className='mb-4'>List of Your Liquidity Positions</p>
                </div>
                <img src="/images/liquidity_page.png" alt="QR Code" />
                <div className='liquidity-bottom-content'>
                    <p className='mb-4'>Your active V3 liquidity positions will appear here</p>
                </div>
                <button className="connect-wallet-btn">Connect Wallet</button>
            </div>
        </div>
    );
};

export default Liquidity_Empty;
