import React from 'react';

const HeroSection = () => {
  return (
    <section className="hero-section ">
      <div className='container hero-section-items'>
        <div className='polygon-stars-image'>
          <img src="/images/Hero_Group.png" className='img-fluid' alt="Polygon stars" />
        </div>
        <div className="hero-text">
          <h2>Welcome to the FLOBUX</h2>
          <h3>BETTER THEN A BANK</h3>
          <p>Join Flobux and experience fee-less trading, staking, and earning. Say goodbye to high fees and hello to financial freedom!</p>
          <p>Experience the future of decentralized finance with Flobux. Enjoy trading and maximize your earnings effortlessly.</p>
          <button className='primary-button'>Get Started</button>
        </div>
        <div className="hero-earth-image">
          <img src="/images/Hero_Earth.png" className='img-fluid' alt="Man holding a product" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
