import React from 'react'

const WhyUs = () => {
    return (
        <>
            <div className='container'>
                <div className='why-us my-5'>
                <div className='why-us-title mt-5'>
                    <h1>Join our Massive Team and Start Banking Today!</h1>
                    <p>We are Your Gateway to Effortless Trading</p>
                </div>
                    <section className="alignment-one">
                        <div className="alignment-one-text">
                            <p>With Flobux, trading cryptocurrencies has never been easier. Our intuitive platform allows you to trade anytime, anywhere, with zero fees on spot currency swaps. Whether you're looking to buy, sell, or exchange cryptocurrencies, Flobux makes it simple and cost-effective.</p>

                            <p>But Flobux is more than just a trading platform. With our staking and liquidity pool features, you can put your idle assets to work and earn rewards directly on our platform. Plus, our seamless sending and receiving functionality makes it easy to transfer cryptocurrencies to friends, family, or anyone else in the world.</p>
                        </div>
                        <div className="alignment-one-image">
                            <img src="/images/img1.png" alt="Man holding a product" />
                        </div>
                    </section>
                    <section className="alignment-two">
                        <div className="alignment-one-text">
                            <h2>Why Choose Flobux?</h2>
                            <p>Flobux is more than just a decentralized exchange (DEX). It's a revolutionary platform that is changing the way people think about finance. With zero fees on spot currency swaps, Flobux offers users a cost-effective way to trade cryptocurrencies without worrying about high transaction costs eating into their profits.</p>
                            <p>But that's not all. Flobux also offers a range of other features, including staking, liquidity pools, and seamless sending and receiving functionality for all major tokens. Whether you're an experienced trader or new to the world of cryptocurrency, Flobux has everything you need to take your digital assets to the next level.</p>
                        </div>
                        <div className="alignment-one-image">
                            <img src="/images/img2.png" alt="Man holding a product" />
                        </div>
                    </section>
                    <section className="alignment-one">
                        <div className="alignment-one-text">
                            <h2>Your Assets Are Safe With Us</h2>
                            <p>At Flobux, we take the security of our users' funds very seriously. That's why we employ industry-leading security measures to ensure that your assets are always safe and secure. From encryption and two-factor authentication (2FA) to cold storage of funds, we use the latest technology to protect your digital assets from unauthorized access and theft.</p>
                            <p>With Flobux, you can trade cryptocurrencies with confidence, knowing that your funds are always secure. Whether you're a casual trader or a seasoned investor, you can trust Flobux to keep your assets safe.
                                Feel free to use or modify these texts for your website or marketing materials! Let me know if you need further assistance.</p>
                        </div>
                        <div className="alignment-one-image">
                            <img src="/images/img3.png" alt="Man holding a product" />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default WhyUs
