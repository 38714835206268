import React from 'react'
import HeroSection from './HeroSection'
import WhyUs from './WhyUs'
import TradeFree from './TradeFreeBanner'
import OurMission from './OurMission'
import OurMainFeatures from './OurMainFeatures'
import Testimonial from './Testimonials'
import ContactUs from '../ContactUs'
import HowToStart from './HowToStart'

const Home = () => {
  return (
    <>
      <HeroSection/>
      <WhyUs/>
      <TradeFree/>
      <OurMission/>
      <OurMainFeatures/>
      <Testimonial/>
      <ContactUs/>
      <HowToStart/>
    </>
  )
}

export default Home
