import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa';
import ChooseProviderModal from './ChooseProviderModal';

const Reveive = () => {
    const [selectedCurrency1, setSelectedCurrency1] = useState('ETH');
    const [selectedCurrency2, setSelectedCurrency2] = useState('BNB');
    const [isChooseProviderModalOpen, setIsChooseProviderModalOpen] = useState(false);

    const currencies = [
        { value: 'ADA', label: 'ADA', image: '/images/ada.png' },
        { value: 'BNB', label: 'BNB', image: '/images/bnb.png' },
        { value: 'ETH', label: 'ETH', image: '/images/eth.png' },
        { value: 'DASH', label: 'DASH', image: '/images/dash.png' },
        { value: 'DAI', label: 'DAI', image: '/images/dai.png' },
        { value: 'ETC', label: 'ETC', image: '/images/etc.png' },
        { value: 'NEXO', label: 'NEXO', image: '/images/nexo.png' },
        { value: 'UNI', label: 'UNI', image: '/images/uni.png' },
    ];

    const openChooseProviderModal = () => {
        setIsChooseProviderModalOpen(true);
    };

    const closeChooseProviderModal = () => {
        setIsChooseProviderModalOpen(false);
    };

    return (
        <div className="swap-container">
            <div className="swap-box">
                <div className=' text-start'>
                    <h5 >Buy Tokens</h5>
                    <p className='mb-4'>Buy tokens in a few clicks</p>
                </div>
                <div className="swap-input">
                    <div className="custom-select" >
                        <div className="selected-option">
                            <img src={currencies.find(cur => cur.label === selectedCurrency1).image} alt={selectedCurrency1} />
                            <p className='m-0'>{selectedCurrency1}</p>
                            <FaChevronDown className="arrow" />
                        </div>
                    </div>
                    <input id="dai-input" type="text" placeholder="22619.5" />
                    <span className="usd-value">~22673.50 USD</span>
                </div>
                <div className="swap-input">
                    <div className="custom-select" >
                        <div className="selected-option">
                            <img src={currencies.find(cur => cur.label === selectedCurrency2).image} alt={selectedCurrency2} />
                            <p className='m-0'>{selectedCurrency2}</p>
                            <FaChevronDown className="arrow" />
                        </div>
                    </div>
                    <input id="dai-input" type="text" placeholder="22619.5" />
                    <span className="usd-value">~22673.50 USD</span>
                </div>
                <div className="send-details" onClick={openChooseProviderModal}>
                    <div className='mb-3'>
                        <select>
                            <option>Mercuryo <span>(1 BNB = $581.42)</span></option>
                        </select>
                    </div>
                </div>
                <button className="connect-wallet-btn">Buy Crypto</button>
                <ChooseProviderModal isOpen={isChooseProviderModalOpen} onClose={closeChooseProviderModal} />
            </div>
        </div>
    )
}

export default Reveive
