import React from 'react'

const OurMission = () => {
    return (
        <>
            <div className='our-mission my-5' id='about-us'>
                <div className='why-us-title mt-5'>
                    <h1>Our Mission: Revolutionizing Finance</h1>
                    <p>At Flobux, we believe in the power of decentralization to create a fairer and more accessible financial system. Our mission is to provide a seamless, secure, and fee-less platform for trading, staking, and managing digital assets.</p>
                </div>
                <div className='cards'>
                    <div className='card1'>
                        <div className='card-image'>
                            <img className='img-fluid' src='/images/mission1.png' alt='Innovative and User-Friendly' />
                        </div>
                        <div className='card-content'>
                            <h3>Innovative and User-Friendly</h3>
                            <p>Flobux is designed with the user in mind. Our intuitive interface and comprehensive features make trading, staking, and managing digital assets easy and accessible for everyone.</p>
                        </div>
                    </div>
                    <div className='card1'>
                        <div className='card-image'>
                            <img className='img-fluid' src='/images/mission2.png' alt='24/7 Customer Support' />
                        </div>
                        <div className='card-content'>
                            <h3>24/7 Customer Support</h3>
                            <p>Our dedicated support team is available around the clock to assist you with any questions or issues. Whether you need help with a transaction or have a question about our features, we’re here to help.</p>
                        </div>
                    </div>
                    <div className='card1'>
                        <div className='card-image'>
                            <img className='img-fluid' src='/images/mission3.png' alt='Community-Driven' />
                        </div>
                        <div className='card-content'>
                            <h3>Community-Driven</h3>
                            <p>At Flobux, we value our community. We listen to our users' feedback and continuously improve our platform based on their needs. Join our growing community and be part of the future of decentralized finance.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurMission
