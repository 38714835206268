import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa';

const Send = () => {
    const [selectedCurrency2, setSelectedCurrency2] = useState('ETH');

    const currencies = [
        { value: 'ADA', label: 'ADA', image: '/images/ada.png' },
        { value: 'BNB', label: 'BNB', image: '/images/bnb.png' },
        { value: 'ETH', label: 'ETH', image: '/images/eth.png' },
        { value: 'DASH', label: 'DASH', image: '/images/dash.png' },
        { value: 'DAI', label: 'DAI', image: '/images/dai.png' },
        { value: 'ETC', label: 'ETC', image: '/images/etc.png' },
        { value: 'NEXO', label: 'NEXO', image: '/images/nexo.png' },
        { value: 'UNI', label: 'UNI', image: '/images/uni.png' },
    ];
    return (
        <div className="swap-container">
            <div className="swap-box">
                <div className=' text-start'>
                    <h5 className='mb-4'>Send Tokens</h5>
                </div>
                <div className="swap-input">
                    <div className="custom-select" >
                        <div className="selected-option">
                            <img src={currencies.find(cur => cur.label === selectedCurrency2).image} alt={selectedCurrency2} />
                            <p className='m-0'>{selectedCurrency2}</p>
                            <FaChevronDown className="arrow" />
                        </div>
                    </div>
                    <input id="dai-input" type="text" placeholder="22619.5" />
                    <span className="usd-value">~22673.50 USD</span>
                </div>
                <div className="send-details">
                    <div className='mb-3'>
                        <label>From:</label>
                        <select>
                            <option>Account 1</option>
                            <option>Account 2</option>
                            <option>Account 3</option>
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label>To:</label>
                        <input type="text" value="0x0434260Aab3013611" />
                    </div>
                    <div className="eth-usd mb-4">
                        <p>1 ETH = 3123.04 USD</p>
                    </div>
                </div>
                <button className="connect-wallet-btn">Enter an amount</button>
            </div>
        </div>
    )
}

export default Send
