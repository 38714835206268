import React from 'react';

const LiquidityPoolsTable = () => {
    const pools = [
        { name: 'DASH / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
        { name: 'DNA / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
        { name: 'DOT / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
        { name: 'EOS / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
        { name: 'ELEC / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
        { name: 'EXMO / ETH', tvl: '$53.40m', volume24h: '$522.93k', volume1w: '$32.63m', volume1m: '$32.63m', fees24h: '$1.87k', apr: '1.28%' },
    ];

    return (
        <div className="liquidity-pools-table">
            <h2>Put your money to work by providing liquidity</h2>
            <p>When you add liquidity to a pool, you can receive a share of its trading volume and potentially snag extra rewards when there are incentives involved.</p>
            <div className="table-controls">
                <p className='m-0'>All Pools(341)</p>
                <div className='live-finshed-buttons'>
                    <button className="live">Live</button>
                    <button className="finished">Finished</button>
                </div>
                <input type="text" placeholder="Search by token or address" />
            </div>
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>TVL</th>
                            <th>Volume (24H)</th>
                            <th>Volume (1W)</th>
                            <th>Volume (1M)</th>
                            <th>Fees (24H)</th>
                            <th>APR</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pools.map((pool, index) => (
                            <tr key={index}>
                                <td>{pool.icon}</td>
                                <td>{pool.name}</td>
                                <td>{pool.volume24h}</td>
                                <td>{pool.volume1w}</td>
                                <td>{pool.volume1m}</td>
                                <td>{pool.fees24h}</td>
                                <td>{pool.apr}</td>
                                <td className='buttons'>
                                    <button className="plus-btn">+</button>
                                    <button className="minus-btn">-</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LiquidityPoolsTable;
